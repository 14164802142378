@import "../base";
@import "../componentes/depoimentos";


/* Menu Áreas */
#menu-areas {
	//background: $azulcpthover;
	font-family: 'Raleway', sans-serif;
	@media (min-width: 768px) {
		background: $azulcpthover;
	}
	.navbar-header {
		@extend .visible-xs;
		margin: 10px 0;
		button{
			@extend .btn;
			@extend .btn-block;
			@extend .btn-success;
			background: $verdecpt;
			font-size: 1em;
		}
	}
	#menu-list-areas {
		ul {
			margin: 0;
			padding: 0;
			&.navbar-nav {
				background: #f4f4f4;
				color: $azulcpt;
				margin-top: 0;
				@media (min-width: 768px) {
					background: none;
					margin: 10px 0;
					width: 100%;
				}
			}
		}
		.nav-pills {
			li {
				a{
					border-bottom: 1px solid #d3d3d3;
					border-radius: 0;
					color: $azulcpt;
					padding: 10px;
					-webkit-transition: background .5s ease-in-out;
					   -moz-transition: background .5s ease-in-out;
					    -ms-transition: background .5s ease-in-out;
					     -o-transition: background .5s ease-in-out;
					        transition: background .5s ease-in-out;
					&:hover, &:focus {
						background: lighten($azulcpthover, 15%);
						//// Mobile
						color: #ffffff;
					}
					@media (min-width: 768px) {
						border: none;
						color: #fff;
						font-size: 13px;
						padding: 5px;
					}
				}
			}
		}
	}
}
/* Banner */
#bannerDestaque {
	@media (max-width: 767px){
		margin-bottom: 40px;
	}
	.carousel-inner {
		.item {
			opacity: 0;
			-webkit-transition-property: opacity;
			-moz-transition-property: opacity;
			-o-transition-property: opacity;
			transition-property: opacity;
			&.active {
				opacity: 1;
				&.left, &.right {
					left: 0;
					opacity: 0;
					z-index: 1;
				}
			}
		}
		& .next.left, & .prev.right {
			opacity: 1;
		}
 	}
	.carousel-indicators {
		left: 0;
		margin-left: 0;
		width: 100%;
		li{
			border-radius: 0;
			height: 15px;
			width: 40px;
		}

		@media (max-width: 767px){
			bottom: -40px;
			li {

				border-color: $azulcpt;
				&.active {
					background-color: $azulcpt;
				}
			}
		}
		@media (min-width: 768px){
			bottom: 0;
			//position: relative;
		}
	}
}

.section-home {
	font-family: 'Raleway', sans-serif;
	margin: 0px;
	padding-bottom: 10px;
	padding-top: 15px;
	@media screen and (min-width: 768px) {
		margin: 20px 0 0px;
	}
}

#incluso-nos-cursos {
	margin-top: 15px;
}
#depoimentos-section {
	background: #f2f2f2;
}

#depoimentos-section {
	//margin-bottom: 20px;
	.btn {
		margin-bottom: 10px;
		@media screen and (max-width: 321px) {
			width: 100%;
		}
	}
}


#blog-section {
	.post-blog {
		.thumbnail {
			border: none;
			&:hover {
				background: #f5f5f5;
				span {
					text-decoration: none;
				}
			}
			.caption {
				h3 {
					color: $azulcpt;
					line-height: 16px;
				}
			}
			.footer-post {
				@extend .text-center;
				span {
					@extend .btn;
					@extend .btn-primary;
					@extend .btn-block;
				}
			}
		}
	}
}

#carousel-parceiros {
	.carousel-control {
		background:none;
		color: $azulcpt;
		width:25px;
		&.left {
			left:-25px;
		}
		&.right {
			right:-25px;
		}
		span {
			top: 40%;
		}
	}
}
#section-counter {
	background: $azulcpthover;
	background: url('../imagens/padrao/bgcontador.jpg') no-repeat;
	background-position: center;
	background-size: cover;
	color: #fff;
	padding: 30px 0;
	.h1 {
		font-size: 1.5em;
	}
	.h4 {
		font-size: .9em;
	}
}

/* Title */
.entry-title {
	color: $azulcpt;
	font-family: 'Raleway', sans-serif;
	font-weight: bold;
	margin-bottom: 0px;
	padding-bottom: 10px;
	@media screen and (max-width: 768px) {
		font-size: 1.5em;
		margin-top: 0;
	}
}
.entry-title-border {
	width: 150px;
    height: 2px;
    background-color: $amarelocpt;
    margin: 0px 0px 25px;
}
.thumbnail-cpt {
	background: #f4f4f4;
	border-radius: 0;
	font-family: 'Raleway', sans-serif;
	// margin-bottom: 30px;
	padding: 0px;
	.caption {
		padding: 10px;
		.btn-cpt {
			@extend .btn;
			@extend .btn-success;
		}
	}
}
/* Cursos */
.thumbnail-curso {
	.featured-image {
		cursor: pointer;
		overflow: hidden;
		position: relative;
		img {
			height: auto;
			-webkit-transition: transform 0.5s;
				-moz-transition: transform 0.5s;
				-ms-transition: transform 0.5s;
				-o-transition: transform 0.5s;
				transition: transform 0.5s;
			width: 100%;
		}
		.label {
			border-radius: 0px;
			bottom: 10px;
			font-size: 100%;
			font-weight: normal;
			left: 10px;
			position: absolute;
			z-index: 2;
		}
		&:hover, &:focus {
			img {
				-webkit-transform: scale(1.25);
				-moz-transform: scale(1.25);
				-ms-transform: scale(1.25);
				-o-transform: scale(1.25);
				transform: scale(1.25);
			}
		}
	}
	.caption {
		@media (max-width: 767px){
			h3 {
				font-size: 14px;
			}
		}
	}
}
.btn-mais-content {
	@extend .btn-lg;
	border-radius: 0;
	font-family: 'Raleway', sans-serif;
}
/* Infraestrutura */
.col-2-5 {
	width: 20%;
	img {
		height: auto;
		width: 100%;
	}
}
/* Videos */
/* Cursos */
.thumbnail-video {
	border: none;
	border-radius: 0;
	font-family: 'Raleway', sans-serif;
	margin-bottom: 0px; //30px;
	padding: 0px;
	.featured-image {
		cursor: pointer;
		position: relative;
		img {
			&.img-responsive {
				width: 100%;
			}
			&.play {
				//bottom:0;
				left: 0;
				margin-left: auto;
				margin-right: auto;
				position: absolute;
				right: 0;
				top: 25%;
				@media screen and (min-width: 768px) {
					top: 30%; //45%;
				}
			}
		}
		&:hover, &:focus {
		}
	}
	.caption {
		padding: 10px;
		@media screen and (max-width: 768px) {
			padding: 5px;
			text-align: center;
		}
	}
}
.section-page {
	font-family: 'Raleway', sans-serif;
	.header-page {
		@extend .col-xs-12;
	}
}

.row-eq-height{
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
  	align-items: center;
}

.publicacoes_recentes, .videos_recentes, .materiais_recentes{
	.row{
		margin-bottom: 10px;
	}
}

.entry-title.titulo-recentes{
	min-height: 89px;
}
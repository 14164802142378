/* Colors */
$verdecpt: #3B9C00;
$verdecpthover: lighten($verdecpt, 5%);
$azulcpt: #1E528F;
$azulcpthover: lighten($azulcpt, 5%);
$amarelocpt:#E8D401;
$amarelocpthover: lighten($amarelocpt, 5%);

$laranjacta: #ff9800;

$facebook: #3e5b98;
$youtube: #e02a20;
$twitter: #4da7de;
$google: #d93e2d;
$googleplus: #dd4b39;
$linkedin: #3371b7;
$instagram: #5851db;

@font-face {
    font-family: 'metropolismedium';
    src: url('../fonts/metropolis-web/metropolis-medium-webfont.woff2') format('woff2'),
         url('../fonts/metropolis-web/metropolis-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'metropolisbold';
//     src: url('../fonts/metropolis-web/metropolis-bold-webfont.woff2') format('woff2'),
//          url('../fonts/metropolis-web/metropolis-bold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'metropolissemi_bold';
//     src: url('../fonts/metropolis-web/metropolis-semibold-webfont.woff2') format('woff2'),
//          url('../fonts/metropolis-web/metropolis-semibold-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

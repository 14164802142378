.depoimento-box {
	margin-bottom: 15px;
	.block-text {
	    background-color: #fff;
	    border: 1px solid #ccc;
	    border-radius: 5px;
	    font-family: 'Raleway', sans-serif;
	    padding: 15px;
	    position: relative;
		&:before {
			border-color: #ccc transparent;
			border-style: solid;
			border-width: 15px 15px 0;
			bottom: -16px;
			content: ' ';
			height: 0;
			left: 30px;
			position: absolute;
			right: auto;
			top: auto;
			width: 0;
		}
		&:after {
			border-color: #fff transparent;
			border-style: solid;
		    border-width: 15px 15px 0;
		    bottom: -14px;
			content: ' ';
			height: 0;
		    left: 30px;
		    position: absolute;
		    right: auto;
		    top: auto;
		    width: 0;
		}
	}
	.person-text {
		font-family: 'Raleway', sans-serif;
		margin-top: 10px;
	    padding: 10px 0 0;
	    position: relative;
	    z-index: 2;
	    img {
	    	height: auto;
	    	width: 64px;
	    }
	}
}

#carousel-reviews {
	margin-bottom: 30px;
	.carousel-control {
		background:none;
		color: $azulcpt;
		@media screen and (min-width: 768px){
			width: 25px;
		}
		@media screen and (max-width: 768px) {
			top: auto;
		}
		&.left {
			left:-25px;
			@media screen and (max-width: 768px) {
				left:0;
			}
		}
		&.right {
			right:-25px;
			@media screen and (max-width: 768px) {
				right:0;
			}
		}
	}
}
@import "../vendor/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../vendor/font-awesome/scss/font-awesome";

@import 'variables';

body {
	margin-top: 119px;
	@media (max-width: 767px) {
		margin-top: 62px;
	}
}
/* Header */
#header {
	@extend .clearfix;
	background: #fff;
	border-bottom: 2px solid $azulcpt;
	padding-bottom: 5px;
	position: fixed;
	top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    @media (max-width: 768px){
    	padding-top: 5px;
    }
}
#top-bar {
	background: $verdecpt;
	border-color: $verdecpt;
	border-radius: 0;
	font-family: 'metropolismedium';
	margin-bottom: 10px;
	// min-height: auto;
	.navbar-nav {
		display: flex;
		justify-content: space-between;
		width: 100%;
		li {

			a {
				color: #fff;
				// padding-bottom: 5px;
				// padding-top: 5px;
				.fa {
					color: $amarelocpt;
				}
				span {
					color: $amarelocpt;
				}
				&:hover {
					background: lighten($azulcpt, 10%);
				}
			}
		}
	}
}


#menu-principal {
	background: #fff;
	border: none;
	font-family: 'metropolismedium';
	margin-bottom: 0;

	@media (max-width: 968px) {
		.navbar-header {
			float: none;
		}
		.navbar-left,.navbar-right {
			float: none !important;
		}
		.navbar-toggle {
			display: block;
		}
		.navbar-collapse {
			border-top: 1px solid transparent;
			box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
		}
		.navbar-fixed-top {
			top: 0;
			border-width: 0 0 1px;
		}
		.navbar-collapse.collapse {
			display: none!important;
		}
		.navbar-nav {
			float: none!important;
			margin-top: 7.5px;
		}
		.navbar-nav>li {
			float: none;
		}
		.navbar-nav>li>a {
			padding-top: 10px;
			padding-bottom: 10px;
		}
		.collapse.in{
			display:block !important;
		}
	}

	.navbar-header {
		.navbar-toggle {
			border: none;
			border-radius: 0;
			color: #fff;
			margin: 5px 15px;
			padding: 5px;
			&:hover, &:focus {
				background: $verdecpthover;
				color: $amarelocpt;
			}
			.icon-bar  {
				//background-color: $verdecpt;
				height: 4px;
			}
		}
		.navbar-brand {
			padding: 0px 15px;
			img {
				@media (max-width: 767px){
					margin-top: 5px;
					max-height: 35px;
				}
				@media (min-width: 768px){
					height: 45px;
				}
			}
		}
	}
	@media (min-width: 969px) and (max-width: 1284px) {

	}

	@media (min-width: 969px) {
		ul{
			li{
				a{
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}
	@media (min-width: 969px) {
		.dropdown:hover {
			.dropdown-menu {
				display: block;
			}
		}
	}

	ul {
		li {
			a {
				color: $azulcpt;
				.fa {
					color: $verdecpt;
				}
			}
		}
		&.dropdown-menu {
			columns: 2;
			-webkit-columns: 2;
			-moz-columns: 2;
			@media (max-width: 767px){
				position:relative;
			}
			li {
				a {
					padding: 10px 20px;
				}
			}
		}
	}
	@media (max-width:767px){
		min-height: 40px;
	}
	ul {
		li {
			a {
				&:hover,&:focus {
					background: #e7e7e7;
				}
			}
		}
		@media (min-width:768px){
			.dropdown-areas {
				min-width: 500px;
			}
		}
		&.dropdown-menu-column {
			@extend .nav;
			@extend .col-xs-12;
			@extend .col-sm-6;
			list-style: none;
			margin: 0;
			width: 100%;
		}
	}
	form {
		@extend .navbar-form;
		// @extend .navbar-right;
		.form-group{
			margin-left: 0px;
		}
		@media (max-width: 767px){
			.form-control {
				width: 80%;
			}
		}
		@media (min-width: 768px){
			button{
				margin-left: -43px;
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
				margin-top: -1px;
				padding-bottom: 7px;
			}
		}
	}
}

/* Newsletter form */
.newsletter-form {
	background: #f4f4f4;
	font-family: 'Raleway', sans-serif;
	padding: 25px 0;
	form {
		.btn {
			margin-top: 15px;
		}
	}
	.text-newsletter{
		color: #194578;
		p.h4{
			line-height: 25px;
		}
	}
	@media screen and (max-width: 768px) {
		margin-top: 0;
		padding: 15px 0;
		form {
			.form-control {
				margin-bottom: 5px;
			}
		}
	}
}

/* Botões Sociais */
.social-networks {
	a {
		@extend .btn;
		&.btn-facebook {
			background: $facebook;
			&:hover, &:focus {
				background: lighten( $facebook, 10%)
			}
		}
		&.btn-twitter {
			background: $twitter;
			&:hover, &:focus {
				background: lighten( $twitter, 10%)
			}
		}
		&.btn-instagram {
			background: $instagram;
			&:hover, &:focus {
				background: lighten( $instagram, 10%)
			}
		}
		&.btn-youtube {
			background: $youtube;
			&:hover, &:focus {
				background: lighten( $youtube, 10%)
			}
		}
		&.btn-googleplus {
			background: $googleplus;
			&:hover, &:focus {
				background: lighten( $googleplus, 10%)
			}
		}
		&.btn-linkedin {
			background: $linkedin;
			&:hover, &:focus {
				background: lighten( $linkedin, 10%)
			}
		}
		color: #fff;
		&:hover, &:focus {
			color: #fff;
		}
	}
}
/* Icones Sociais */
.soc {
    list-style: none;
	display: inline;
	margin-left:0 !important;
	padding: 0;
    li {
    	display: inline;
		margin: 0 5px;
    	a {
			background: #fff;
			color: $azulcpt;
			display:inline-block;
			position:relative;
			margin:0 auto 0 auto;
			-moz-border-radius:50%;
			-webkit-border-radius:50%;
			border-radius:50%;
			text-align:center;
			width: 30px;
			height: 30px;
			font-size: 1em;
			-webkit-transition: background .4s ease-in-out;
    		   -moz-transition: background .4s ease-in-out;
    		    -ms-transition: background .4s ease-in-out;
    		     -o-transition: background .4s ease-in-out;
    		        transition: background .4s ease-in-out;
			i {
				margin:0;
				line-height:30px;
				text-align: center;
			}
			&:hover, &:focus {
				-webkit-box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.6);
				-moz-box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.6);
				box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.6);
			}
    	}
    }

	.soc-facebook:hover, .soc-facebook:focus {
    	background-color: #3e5b98;
	}

	.soc-youtube:hover, .soc-youtube:focus {
	    background-color: #e02a20;
	}
	.soc-twitter:hover, .soc-twitter:focus {
	    background-color: #4da7de;
	}

	.soc-google:hover, .soc-google:focus {
	    background-color: #d93e2d;
	}

	.soc-linkedin:hover, .soc-linkedin:focus {
	    background-color: #3371b7;
	}

	.soc-instagram:hover, .soc-instagram:focus {
	    background-color: #9c7c6e;
	}
}
#footer {
	background: $azulcpt;
	border-top: 3px solid $verdecpt;
	color: #fff;
	font-family: 'Raleway', sans-serif;
	padding: 0 0 10px;
	.footer-title {
		border-bottom: 1px solid #fff;
	}
	.footer-links {
		background: $azulcpthover;
		font-size: 1em;
		padding: 20px 0;
		ul {
			li {
				a {
					color: #fff;
				}
			}
		}
	}
	.copy {
		padding-top: 10px;
		p {
			margin: 0;
		}
		.phone-footer {
			color: #fff;
			display: block;
			margin: 0;
			padding: 5px 15px;
			&:hover, &:focus {
				background: $azulcpthover;
				text-decoration: none;
			}
		}
	}
	.bancos {
		list-style: none;
		padding-left: 0;
		li {
			display: inline-table;
		}
		@media screen and (max-width: 768px) {
			margin: 15px 0;
		}
	}
}

.section-page {
	@extend .container;
	.content-page {
		//@extend .container;
		.header-page {
			border-bottom: 1px solid #eee;
			color: $azulcpt;
			margin-bottom: 15px;
			.entry-title {
				@extend .text-uppercase;
				// @media screen and (max-width: 425px) {
					font-size: 2em;
				// }
			}
			.entry-subtitle {
				// @media screen and (max-width: 425px) {
					font-size: 1.6em;
				// }
			}
		}
		.header-section {
			@extend .header-page;
		}
	}
}


#popup {
	@media (max-width:767px){
		padding-right: 0;
	}
	@media (min-width:768px){
		padding-left: 17px;
	}
	.modal-dialog {
		width: auto;
		max-width:800px;
		.modal-content {
			width: 100%;
		}
	}
	.modal-header {
	    position: absolute;
	    z-index: 111111111;
	    right: 0;
	    border: none;
	    .close {
	    	color: #fff;
	    	opacity: 1;
	    }
	}
	.modal-body {
		padding: 0;
	}
}
